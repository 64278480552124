export const cancellableFetch = (target, name, descriptor) => {
    const original = descriptor.value;

    descriptor.value = async function() {
        try {
            return await original.apply(this);
        } catch (err) {
            if (axios.isCancel(err)) {
                debug.info("Fetch was cancelled.");
            } else {
                throw err;
            }
        }
    };

    return descriptor;
};

<template>
    <div class="page">
        <div class="content">
            <div class="page-title bg-white">
                <h1 class="typography-h1 title bg-white">
                    Nastavení
                </h1>
            </div>
            <div class="box">
                <div class="row box-inner">
                    <div class="col-4">
                        <q-input
                            @input="disabled = false"
                            v-model="settings[0].value"
                            class="my-input my-input--square my-input--square full-width"
                            label="Výchozí počet lístků" type="text"
                        />
                    </div>
                    <div class="col-4">
                        <q-input
                            @input="disabled = false"
                            v-model="settings[1].value"
                            class="my-input my-input--square my-input--square full-width"
                            label="Výchozí cena lístku" type="text"
                        />
                    </div>
                    <div class="col-4">
                        <q-input
                            @input="disabled = false"
                            v-model="settings[2].value"
                            class="my-input my-input--square my-input--square full-width"
                            label="DPH" type="text"
                        >
                            <template #append>
                                <span class="body">%</span>
                            </template>
                        </q-input>
                    </div>
                </div>
                <div class="flex justify-end q-mt-lg q-pr-md">
                    <q-btn type="submit" style="min-width: 165px;"
                           class="my-btn my-btn--primary"
                           color="black"
                           :disable="disabled"
                           @click="saveAll"
                           :label=" $t('update')"/>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data: ()=> ({
        disabled: true,
    }),

    methods: {
        async saveAll() {
            this.disabled = true;
            this.settings = await this.updateAll('settings', this.settings.map(s=>({...s, value:s? +s.value : null})));
            notifyOk("Nastavení bylo uloženo.");
        },
    },

    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
    },

    meta: {
        title: 'Nastavení',
    },
};
</script>
<style lang="scss" scoped>
.page{
    overflow: hidden;
    height: 100vh;
}
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    padding: 24px 16px;
    min-height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.box{
    padding: 56px 0 32px;
    margin: 32px 16px;
    background: #fff;
    border: 1px solid $black-300;
}
.box-inner{
    margin-left: -1px;
    margin-right: -1px;
}
</style>
